import { GET_ALL_TAXONS, GET_TAXON } from '../queries';
import { TaxonInterface } from '../interfaces/Taxon';
import { executeQuery } from '../Utils/helperFunctions';

class TaxonsService {
    async getTaxon(code: string | null | undefined): Promise<TaxonInterface> {
        try {
            const { data } = await executeQuery<{ taxon: TaxonInterface }>(GET_TAXON(code || ''));
            return data.taxon;
        } catch (error) {
            console.error('Error fetching taxon:', error);
            throw error;
        }
    }

    async getAllTaxons(page: number = 1, itemsPerPage: number = 30): Promise<TaxonInterface[]> {
        try {
            const { data } = await executeQuery<{ taxons: TaxonInterface[] }>(GET_ALL_TAXONS(page, itemsPerPage));
            return data.taxons;
        } catch (error) {
            console.error('Error fetching taxons:', error);
            throw error;
        }
    }
}

export default TaxonsService;
