type Params = Record<string, string | number | boolean | (string | number | boolean)[]>;

const buildQueryParams = (params: Params): string => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
        const value = params[key];
        if (Array.isArray(value)) {
            value.forEach(item => searchParams.append(`${key}[]`, item.toString()));
        } else if (value !== undefined) {
            searchParams.append(key, value.toString());
        }
    });
    return searchParams.toString();
};

const buildPath = (base: string, params?: Params): string => (params ? `${base}?${buildQueryParams(params)}` : base);

const SHOP_PREFIX = 'shop';
const PRODUCTS_PREFIX = 'products';
const PUBLIC_PREFIX = 'public';
const TAXONS_PREFIX = 'taxons';

export const productsPathBuilder = ({ page, itemsPerPage, codes }: { page: number; itemsPerPage: number; codes?: string[] | string }) => {
    const params: Params = { page, itemsPerPage };
    if (codes) {
        params['productTaxons.taxon.code'] = codes;
    }
    return buildPath(`${SHOP_PREFIX}/${PRODUCTS_PREFIX}`, params);
};

export const productsByCodeVariantsPathBuilder = ({ codes }: { codes?: string[] | string }) =>
    `${SHOP_PREFIX}/${PRODUCTS_PREFIX}/${Array.isArray(codes) ? codes.join(',') : codes}`;

export const attributesPathBuilder = ({ codes }: { codes?: string[] | string }) =>
    `${SHOP_PREFIX}/${PRODUCTS_PREFIX}/${Array.isArray(codes) ? codes.join(',') : codes}/attributes`;

export const filterItemsPathBuilder = () => `${SHOP_PREFIX}/product-types`;

export const taxonPathBuilder = ({ page, itemsPerPage, code }: { page?: number; itemsPerPage?: number; code?: string }) => {
    const params: Params = {};
    if (page !== undefined) {
        params.page = page;
    }
    if (itemsPerPage !== undefined) {
        params.itemsPerPage = itemsPerPage;
    }
    return code ? `${SHOP_PREFIX}/${TAXONS_PREFIX}/${code}` : buildPath(`${SHOP_PREFIX}/${TAXONS_PREFIX}`, params);
};

export const countryPathBuilder = ({ code }: { code?: string }) => `${SHOP_PREFIX}/countries/${code}`;

export const productsVariantsPathBuilder = ({
    page,
    itemsPerPage,
    productId,
    order,
    code,
}: {
    page: number;
    itemsPerPage: number;
    productId: number;
    order: string;
    code?: string;
}) => {
    const params: Params = { page, itemsPerPage, product: productId, 'order[price]': order };
    return code ? `${SHOP_PREFIX}/product-variants/${code}` : buildPath(`${SHOP_PREFIX}/product-variants`, params);
};

export const partnerBrandsPathBuilder = () => `${PUBLIC_PREFIX}/products-partners`;

export const associationByCodePathBuilder = ({ code }: { code?: string }) => `${SHOP_PREFIX}/countries/${code}`;
