const generateTaxonFields = (depth: number): string => {
    if (depth === 0) return '';
    return `
    children {
      id
      name
      code
      enabled
      ${generateTaxonFields(depth - 1)}
    }
  `;
};

export const taxonQueryFields = `
  id
  name
  code
  enabled
  ${generateTaxonFields(3)}
`;

export const productVariantFields = `
  minDeliveryTime
  maxDeliveryTime
  stockType {
    id
    label
  }
  preOrderable
  onHold
  onHand
  id
  code
  product
  optionValues
  name
  availableToSell
  emrysCoins
  advantages {
    type
    unitAmount
    amount
  }
  inStock
  price
  originalPrice
`;

export const productFields = `
  id
  name
  code
  description
  shortDescription
  inStock
  images {
    id
    mediaUrl {
      sylius_small
      sylius_medium
      sylius_large
      sylius_shop_product_carousel
    }
  }
  variants {
    code
    inStock
    price
    originalPrice
    emrysCoins
    advantages {
      type
      unitAmount
      amount
    }
    translations {
      fr_FR {
        name
      }
      en_US {
        name
      }
    }
  }
  availableCountries
  bundle {
    items {
      productVariant {
        code
        inStock
        price
        originalPrice
        emrysCoins
        advantages {
          type
          unitAmount
          amount
        }
        translations {
          fr_FR {
            name
          }
          en_US {
            name
          }
        }
      }
      quantity
    }
    isPacked
  }
  validityLength
  cumulative
  productDivisibleChoice {
    id
    name
    isDivisible
  }
  productType {
    id
    name
  }
  productTaxons
  mainTaxon
  reviews
  averageRating
  options
  associations
  createdAt
  updatedAt
  refundConditions
  isBundle
  slug
  defaultVariant
`;
