import { gql } from '@apollo/client';
import {
    associationByCodePathBuilder,
    attributesPathBuilder,
    countryPathBuilder,
    filterItemsPathBuilder,
    partnerBrandsPathBuilder,
    productsByCodeVariantsPathBuilder,
    productsPathBuilder,
    productsVariantsPathBuilder,
    taxonPathBuilder,
} from './Utils/buildPath';

import { taxonQueryFields, productVariantFields, productFields } from './queryFragments';

export const GET_TAXON = (code: string) => gql`
  query GetTaxon {
    taxon @rest(type: "Taxon", path: "${taxonPathBuilder({ code })}") {
      ${taxonQueryFields}
    }
  }
`;

export const GET_ALL_TAXONS = (page: number, itemsPerPage: number) => gql`
  query GetAllTaxons {
    taxons @rest(type: "Taxon", path: "${taxonPathBuilder({ page, itemsPerPage })}") {
      ${taxonQueryFields}
    }
  }
`;

export const GET_ALL_PRODUCT_VARIANTS_BY_PRODUCT = (page: number, itemsPerPage: number, productId: number, order: string) => gql`
  query GetAllProductVariantsByProduct($page: Int!, $itemsPerPage: Int!, $productId: Int!, $order: String!) {
    productVariants(page: $page, itemsPerPage: $itemsPerPage, productId: $productId, order: $order) @rest(type: "ProductVariant", path: "${productsVariantsPathBuilder(
        { page, itemsPerPage, productId, order },
    )}") {
      ${productVariantFields}
    }
  }
`;

export const GET_PRODUCT_DETAILS_BY_VARIANT_CODE = (page: number, itemsPerPage: number, productId: number, order: string, code: string) => gql`
  query GetAllProductVariantsByProduct {
    products @rest(type: "Product", path: "${productsVariantsPathBuilder({ page, itemsPerPage, productId, order, code })}") {
      id
      name
      price
      productId
    }
  }
`;

export const GET_ALL_PRODUCTS = (page: number, itemsPerPage: number, codes: string[]) => gql`
  query GetAllProducts {
    products @rest(type: "Product", path: "${productsPathBuilder({ page, itemsPerPage, codes })}") {
      ${productFields}
    }
  }
`;

export const GET_PRODUCT_BY_CODE = (page: number, itemsPerPage: number, codes: string[] | string) => gql`
  query GetProductByCode {
    products @rest(type: "Product", path: "${productsPathBuilder({ page, itemsPerPage, codes })}") {
      ${productFields}
    }
  }
`;

export const GET_VARIANTS_PRODUCT_BY_CODE = (codes: string[] | string) => gql`
  query GetProductVariantsByCode {
    products @rest(type: "Product", path: "${productsByCodeVariantsPathBuilder({ codes })}") {
      ${productFields}
    }
  }
`;

export const GET_PRODUCT_ATTRIBUTES = (codes: string[] | string) => gql`
  query GetProductAttributes($code: String!) {
    products @rest(type: "Product", path: "${attributesPathBuilder({ codes })}") {
      id
      name
      value
    }
  }
`;

export const GET_FILTER_ITEMS = () => gql`
  query GetFilterItems {
    filterItems @rest(type: "FilterItem", path: "${filterItemsPathBuilder()}") {
      id
      name
      physical
    }
  }
`;

export const GET_COUNTRY_BY_CODE = (code: string) => gql`
  query GetCountryByCode($code: String!) {
    country(code: $code) @rest(type: "Country", path: "${countryPathBuilder({ code })}") {
      code
      name
      provinces {
        code
        name
      }
    }
  }
`;

export const GET_ASSOCIATION_BY_CODE = (code: string) => gql`
  query GetAssociationByCode {
    productAssociation@rest(type: "PartnerBrands", path: "${associationByCodePathBuilder({ code })}") {
      id
      name
      code
    }
  }
`;

export const GET_PARTNER_BRANDS = () => gql`
  query GetOurPartnerBrands {
    partnerBrands @rest(type: "PartnerBrands", path: "${partnerBrandsPathBuilder()}") {
      name
      products {
        name
        images {
          path
          mediaUrl {
            sylius_small
            sylius_medium
            sylius_large
          }
        }
      }
    }
  }
`;
